<template>
  <base-layout class="pdf-view">
    <base-pdf :url="url" />
  </base-layout>
</template>

<script>
import BasePdf from "@/components/BasePdf";

export default {
  components: {
    BasePdf
  },

  data() {
    return {
      url: undefined
    };
  },

  created() {
    this.url = this.$route.query.url;
  },
  methods: {}
};
</script>

<style lang="less" scoped></style>
